// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import {IS_DEV} from './constants/env';

const geniamFireConfig = {
  apiKey: 'AIzaSyBROAxpDRqRIqZvrAGE7UK7uvaIUcxooS0',
  authDomain: 'geniam-c8d4c.firebaseapp.com',
  databaseURL: 'https://geniam-c8d4c.firebaseio.com',
  projectId: 'geniam-c8d4c',
  storageBucket: 'geniam-c8d4c.appspot.com',
  messagingSenderId: '92022981782',
  appId: '1:92022981782:web:17a2ab710f867e60b56eb7',
  measurementId: 'G-ZZVDYP9EN4',
};
// Initialize Firebase
const geniamApp = firebase.initializeApp(geniamFireConfig);

export const GeniamFire = geniamApp;

if (typeof window === 'object') window.GeniamFire = GeniamFire;

export const callableFunctions = firebase.app().functions('asia-northeast1');
if (IS_DEV)
  callableFunctions.useFunctionsEmulator(
      process.env.REACT_APP_FIRE_FUNCTIONS_EMULATOR);
