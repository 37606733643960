import {useAuthState} from 'react-firebase-hooks/auth';
import * as React from 'react';
import {useContext, useEffect} from 'react';

const FireAuthContext = React.createContext();
FireAuthContext.displayName = 'FireAuthContext';

export const FireAuthProvider = ({appAuth, children}) => {
  const auth = useAuth(appAuth);
  return (
      <FireAuthContext.Provider value={auth}>
        {children}
      </FireAuthContext.Provider>
  );
};
export const useFireAuth = () => {
  return useContext(FireAuthContext);
};

export default function useAuth(appAuth) {
  console.log('useAuth load');
  const [user, loading, err] = useAuthState(appAuth);

  useEffect(() => {
    if (loading) return;
    if (!loading && !user) {
      //to sign in page
      if (!window.location.pathname.toLowerCase().includes('signin')) {
        window.location = '/signin';
        return;
      }
    }
    // console.log({user});

  }, [user, loading]);

  if (err) console.log('auth error', err);
  return [user, loading, err];
}
