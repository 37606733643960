import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import {useFireAuth} from './hooks/useAuth';

const Home = lazy(() => import('./routes/Home'));
const SignIn = lazy(() => import('./routes/SignIn'));

const App = () => {
  console.log('App load');
  useFireAuth();
  return (
      <Router>
        <Suspense fallback={<LinearProgress/>}>
          <Switch>
            {/*<Route exact path="/" component={Home}/>*/}
            <Route path="/signIn" component={SignIn}/>
            <Route path="/:service?/:menu?" component={Home}/>
          </Switch>
        </Suspense>
      </Router>
  );
};

export default App;
